.modalContent {
  max-width: none;
  padding  : 24px;
  border-radius: 20px;
  @media (max-width: 767px) {
    font-size: 12px;
    max-height: 500px;
  }
  .btnClose {
    position: absolute;
    top     : -40px;
    right   : 0;
    color   : white;
    cursor  : pointer;

    i {
      font-size: 30px;
    }
  }

  .header {
    display    : flex;
    align-items: center;

    .img {
      width          : 100px;
      margin-bottom: 10px;
      display        : flex;
      justify-content: center;
      align-items    : center;
      text-align     : center;
      height         : 200px;
      font-weight    : bolder;
      border-radius  : 20px;
      background     : #dcdcdc;
    }

    .btnSelect {
      margin: auto;
      text-align: center;

      @media (max-width: 767px) {
        width: 100px;
        margin: 10px;
      }
    }
  }

  .charityName {
    margin: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top : 24px;
    color      : var(--yellow);
    font-size  : 28px;
    font-weight: bold;
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  .link {
    font-size: 20px;
    @media (max-width: 767px) {
      font-size: 12px;
    }

    i {
      margin-right: 8px;
    }
  }

  .detailItem {
    margin-top: 8px;
    font-size : 16px;
    color     : var(--gray);
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .description {
    margin-top: 16px;
    font-size : 18px;
    color     : var(--gray);
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
}