@import "../../../css/_mixins.scss";

.modalTitle {
  font-weight: bold;
}
.btn{
  font-weight: bold;
  width: 100px;
  font-size: 16px;
  border-radius: 30px;
  padding-inline: 7px;
  height: 55px;
  float: right;
  margin-inline: 20px;
}
.modalYesBtn {
  width: auto + 10px;
  border: 3px solid var(--yellow);
  color: var(--yellow);
  padding-inline: 5px;
}
.modalNoBtn {
  padding-inline: 5px;
  font-size: 16px;
  border: 3px solid var(--yellow);
  color: var(--white);
  background-color: var(--yellow);
}
.container {
  display   : flex;
  width     : 100%;
  min-height: 80vh;
  text-align: center;

  @include small-desktop {
    flex-direction: column-reverse;
  }

  .btnStepBack {
    position: relative;
    width: 180px;
    height: 36px;
    margin: 16px auto 0;
    color: var(--text-gray);
    font-size: 14px;
    font-weight: bold;
    border: 2px solid var(--text-gray);
    background-color: var(--white);
    border-radius: 35px;
    float: right;

    i {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      font-size: 20px;
    }
  }
  .cartContent {
    position        : relative;
    padding         : 82px 30px 82px 42px;
    flex-shrink     : 0;
    text-align      : left;
    background-color: var(--concrete);

    @include small-desktop {
      width  : 100%;
      padding: 60px;
    }

    @include mobile {
      padding: 32px 16px;
    }

    .cartContentWrapper {
      width: 420px;

      @include small-desktop {
        margin: 0 auto;
      }

      @include mobile {
        width: 100%;
      }

      .cartLabel {
        font-size     : 23px;
        line-height   : 28px;
        font-weight   : 700;
        letter-spacing: -0.46px;
        color         : var(--red);
      }

      .cartItems {
        .cartItem {
          display    : flex;
          align-items: center;
          margin-top : 40px;

          .itemImage {
            position       : relative;
            display        : flex;
            justify-content: center;
            align-items    : center;
            width          : 115px;
            height         : 71px;
            flex-shrink    : 0;
            background     : var(--white);
            border-radius  : 6px;

            .itemQuantity {
              position      : absolute;
              top           : 0;
              left          : 50%;
              transform     : translate(-50%, -50%);
              width         : 48px;
              height        : 48px;
              border        : 1px solid var(--gray);
              background    : var(--white);
              color         : var(--red);
              text-align    : center;
              font-size     : 29px;
              line-height   : 45px;
              font-weight   : 700;
              letter-spacing: -0.58px;
              border-radius : 50%;
            }

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }

          .itemDetail {
            flex       : 1;
            margin-left: 20px;

            @include small-desktop {
              margin-left: 8px;
            }

            .itemName {
              color         : var(--red);
              font-size     : 19px;
              line-height   : 23px;
              font-weight   : 700;
              letter-spacing: -0.38px;

              @include mobile {
                font-size: 18px;
              }
            }

            .itemDesc {
              margin-top    : 5px;
              color         : var(--gray);
              font-size     : 16px;
              font-style    : italic;
              line-height   : 19px;
              letter-spacing: -0.32px;

              @include mobile {
                font-size: 14px;
              }
            }
          }

          .itemPrice {
            color         : var(--gray);
            font-size     : 25px;
            line-height   : 30px;
            text-align    : right;
            letter-spacing: -0.5px;

            @include mobile {
              font-size: 22px;
            }
          }
        }

        .actions {
          display: flex;
          justify-content: flex-end;
          gap: 20px;
          color: var(--yellow);
          
          .btnAction {
            font-size: 14px;
            cursor: pointer;
          }
        }
      }

      .costItem {
        display        : flex;
        justify-content: space-between;
        align-items    : center;
        margin-top     : 24px;

        .costType {
          color         : var(--red);
          font-size     : 19px;
          line-height   : 23px;
          font-weight   : 700;
          letter-spacing: -0.38px;
        }

        .costValue {
          color         : var(--gray);
          font-size     : 25px;
          line-height   : 30px;
          text-align    : right;
          letter-spacing: -0.5px;
        }
      }

      .checkReminder {
        margin-top: 16px;
      }
    }
  }

  .content {
    position       : relative;
    flex           : 1;
    display        : flex;
    justify-content: center;
    align-items    : center;
    flex-direction : column;
    padding        : 0 100px;

    @include small-desktop {
      padding: 100px;
    }

    @include mobile {
      padding: 80px 16px;
    }

    .cartDetail {
      display       : flex;
      align-items   : center;
      font-size     : 39px;
      letter-spacing: -0.78px;
      color         : var(--red);

      i {
        margin-right: 10px;
        font-size   : 50px;
        color       : var(--red);
      }
    }

    .description {
      margin        : 20px 0 10px;
      font-size     : 39px;
      line-height   : 48px;
      color         : var(--gray);
      text-align    : center;
      font-weight   : 500;
      letter-spacing: -0.78px;
    }

    .btnLogin {
      margin-top: 20px;
      width     : 446px;

      @include mobile {
        width: 100%;
      }
    }

    .checkoutButtons {
      margin-top: 40px;
      width     : 446px;

      @include mobile {
        width: 100%;
      }
    }

    .btnCorporateCheck {
      width    : 446px;
      height   : 45px;
      font-size: 18px;

      @include mobile {
        width: 100%;
      }
    }

    .btnStepBack {
      position: relative;
      margin-top: 40px;
      width           : 205px;
      height          : 41px;
      color           : var(--text-gray);
      font-size       : 17px;
      font-weight     : bold;
      border          : 3px solid var(--text-gray);
      background-color: var(--white);
      border-radius   : 35px;

      i {
        position : absolute;
        top      : 50%;
        right    : 16px;
        transform: translateY(-50%);
        font-size: 20px;
      }
    }
  }
}