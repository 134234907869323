@import "../../css/_mixins.scss";

.container {
  .stepLabel {
    display        : flex;
    justify-content: center;
    align-items    : center;
    font-size      : 35px;
    line-height    : 32px;
    color          : var(--white);
    letter-spacing : -0.78px;

    @include tablet {
      display   : block;
      text-align: center;
    }

    span {
      margin        : 8px 0 0 8px;
      font-size     : 26px;
      line-height   : 32px;
      letter-spacing: -0.52px;

      @include mobile {
        display: block;
      }
    }

    .cartDetail {
      display       : flex;
      align-items   : center;
      position      : absolute;
      right         : 80px;
      font-size     : 39px;
      letter-spacing: -0.78px;

      @include tablet {
        position       : relative;
        justify-content: center;
        right          : 0;
        margin-top     : 16px;
      }

      i {
        margin-right: 10px;
        font-size   : 50px;
      }
    }
  }

  .content {
    position      : relative;

    .btnStepBack {
      position        : absolute;
      bottom          : 40px;
      right           : 60px;
      width           : 205px;
      height          : 41px;
      color           : var(--text-gray);
      font-size       : 17px;
      font-weight     : bold;
      border          : 3px solid var(--text-gray);
      background-color: var(--white);
      border-radius   : 35px;

      i {
        position : absolute;
        top      : 50%;
        right    : 16px;
        transform: translateY(-50%);
        font-size: 20px;
      }
    }
  }
}